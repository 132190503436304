@import url("https://fonts.googleapis.com/css2?family=Lora&family=Open+Sans:wght@500&family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:ital@1&family=Open+Sans:wght@500&family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playwrite+GB+J:ital,wght@0,100..400;1,100..400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Caveat&family=Playwrite+GB+J:ital,wght@0,100..400;1,100..400&display=swap");

:root {
  --font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --navbar-brand-font-size: 1.25rem;
  --navbar-text-color: #fff;
  --basic-font-heading: "Playwrite GB J", serif;
  --text-font: "Caveat", serif;
}
body {
  font-family: "Lora", serif;
  margin: 0;
}

.about-container p {
  margin: 2rem 0;
  font-size: 20px;
  font-family: var(--basic-font-heading);
}
.contact-info {
  font-family: var(--basic-font-heading);
}
#mainNav {
  font-family: var(--font-family);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1999;
  background-color: transparent !important;
}
#mainNav .navbar-brand {
  font-weight: 800;
  font-size: var(--navbar-brand-font-size);
  white-space: nowrap;
  color: var(--navbar-text-color);
}
#mainNav .nav-item > .nav-link {
  font-weight: 800;
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  font-size: 0.75rem;
  color: var(--navbar-text-color);
}
.header {
  position: relative;
  padding-top: calc(8rem + 57px);
  padding-bottom: 8rem;
  background: no-repeat center center;
  background-size: cover;
  margin-bottom: 3rem;
}
.header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #212529;
  opacity: 0.5;
}
.page-heading {
  text-align: center;
  color: #fff;
}
.page-heading h1 {
  font-size: 3rem;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 800;
  font-size: 2.5rem;
  font-family: var(--basic-font-heading);
}
.page-heading span {
  font-size: 1.5rem;
  font-weight: 300;
  margin-top: 0.625rem;
  font-family: var(--text-font);
}
.post-preview {
  text-decoration: none !important;
}
.post-preview .post-title {
  font-size: 1.25rem;
  font-family: var(--basic-font-heading);
  margin-top: 1.875rem;
  margin-bottom: 0.625rem;
  font-weight: 600;
}
.post-preview > .post-link {
  text-decoration: none;
  color: #212529;
}
.post-preview > .post-link:hover h2 {
  color: #0085a1;
  text-decoration: underline;
}
.post-preview .post-subtitle {
  font-family: var(--text-font);
  margin-bottom: 0.625rem;
  font-size: 1.15rem;
}
.post-footer > .post-meta {
  font-size: 0.65rem;
  font-style: italic;
  margin-top: 0;
  color: #6c757d;
  font-family: var(--basic-font-heading);
}
.post-duration {
  font-size: 0.65rem;
  font-style: italic;
  margin-top: 0;
  color: #6c757d;
  font-family: var(--basic-font-heading);
}
.post-content {
  margin: 2rem 0;
  font-family: var(--text-font);
  font-size: 1.25rem;
}
.footer-links .footer-item {
  font-size: 0.75rem;
  background-color: #212529;
}
.footer-links .footer-item:hover {
  background-color: #0085a1;
}
.footer-text {
  font-family: var(--basic-font-heading);
  font-size: 0.65rem;
}
.email-sent {
  font-family: "Lora", serif;
  font-weight: 800;
  letter-spacing: 0.5px;
}
.not-found__container {
  margin: auto;
  width: 100%;
}
.not_found__title {
  font-family: var(--basic-font-heading);
  font-size: 0.85rem;
  margin: 1.95rem auto;
}

@media screen and (max-width: 400px) {
  .post-preview .post-title {
    font-size: 1.05rem;
  }

  .post-preview .post-subtitle {
    font-size: 0.95rem;
  }
}

@media screen and (min-width: 768px) {
  .not-found__container {
    margin: auto;
    width: 50%;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Lora&family=Open+Sans:wght@500&family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:ital@1&family=Open+Sans:wght@500&family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playwrite+GB+J:ital,wght@0,100..400;1,100..400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Caveat&family=Playwrite+GB+J:ital,wght@0,100..400;1,100..400&display=swap");

:root {
  --font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --navbar-brand-font-size: 1.25rem;
  --navbar-text-color: #fff;
  --basic-font-heading: "Playwrite GB J", serif;
  --text-font: "Caveat", serif;
}

/* General container (you already have something similar) */
.d-flex .border-none { 
    border: 1px solid #ced4da; 
    border-radius: 5px;          
    padding: 8px 12px;
}

/* Individual day cells */
.react-datepicker__day {
    background-color: #f5f5f5; /* Example */
}

/* Selected day */
.react-datepicker__day--selected {
    background-color: #3498db; /* Example highlight color */
    color: white;
}

/* Hover effects, etc. */
.react-datepicker__day:hover {
     background-color: #e0e0e0;
}

.react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text, .react-datepicker__year-text { 
    font-family: var(--basic-font-heading);
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    font-family: var(--text-font);
}
